<template>
  <div class="product-item-box">
    <ul>
        <li>
            <div class="product-item-main-pic-box">
                <img class="product-item-main-pic" src="https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/f7ef5166c03348cb8e3cf92c19d2dc60_mergeImage.png" alt="商品主图" >
            </div>
            <div class="product-item-main-info-box">
                <p class="product-item-main-info-title">O155500 号 星悦会员4罕见双15武器连体号</p>
                <p class="product-item-main-info-game-zone">游戏区  |  广东区/十六服</p>
                <div class="product-item-main-info-price-box">
                    <p class="product-item-main-info-price-symbol">¥</p>
                    <p class="product-item-main-info-price-text">113.40</p>
                </div>
            </div>
        </li>
        <li>
            <div class="product-item-main-pic-box">
                <img class="product-item-main-pic" src="https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/75094884b0c94777852036f5e68ad789_mergeImage.png" alt="商品主图" >
            </div>
            <div class="product-item-main-info-box">
                <p class="product-item-main-info-title">O153611 号 上海1区，29角色，不可二次，信用分0，无敏感操作，不可发言</p>
                <p class="product-item-main-info-game-zone">游戏区  |  广东区/十六服</p>
                <div class="product-item-main-info-price-box">
                    <p class="product-item-main-info-price-symbol">¥</p>
                    <p class="product-item-main-info-price-text">1600</p>
                </div>
            </div>
        </li>
        <li>
            <div class="product-item-main-pic-box">
                <img class="product-item-main-pic" src="https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/4ee4a03fcbfc47bdab960ba33b2dad4e_mergeImage.png" alt="商品主图" >
            </div>
            <div class="product-item-main-info-box">
                <p class="product-item-main-info-title">O155469 号 双尊剑魔，武器白13，耳环左槽红13，全身11和10，白金玲珑毕</p>
                <p class="product-item-main-info-game-zone">游戏区  |  广东区/十六服</p>
                <div class="product-item-main-info-price-box">
                    <p class="product-item-main-info-price-symbol">¥</p>
                    <p class="product-item-main-info-price-text">1100</p>
                </div>
            </div>
        </li>
        <li>
            <div class="product-item-main-pic-box">
                <img class="product-item-main-pic" src="https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/a41c3333b92a4afeb0d321fb3d41af83_mergeImage.png" alt="商品主图" >
            </div>
            <div class="product-item-main-info-box">
                <p class="product-item-main-info-title"> C645744 号  【安卓QQ166区】【包人脸】 贵族9 荣耀典藏4 传说皮肤13 史诗</p>
                <p class="product-item-main-info-game-zone">游戏区  |  广东区/十六服</p>
                <div class="product-item-main-info-price-box">
                    <p class="product-item-main-info-price-symbol">¥</p>
                    <p class="product-item-main-info-price-text">200</p>
                </div>
            </div>
        </li>
        <li>
            <div class="product-item-main-pic-box">
                <img class="product-item-main-pic" src="https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/f7ef5166c03348cb8e3cf92c19d2dc60_mergeImage.png" alt="商品主图" >
            </div>
            <div class="product-item-main-info-box">
                <p class="product-item-main-info-title">O155500 号 星悦会员4罕见双15武器连体号</p>
                <p class="product-item-main-info-game-zone">游戏区  |  广东区/十六服</p>
                <div class="product-item-main-info-price-box">
                    <p class="product-item-main-info-price-symbol">¥</p>
                    <p class="product-item-main-info-price-text">113.40</p>
                </div>
            </div>
        </li>
        <li>
            <div class="product-item-main-pic-box">
                <img class="product-item-main-pic" src="https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/75094884b0c94777852036f5e68ad789_mergeImage.png" alt="商品主图" >
            </div>
            <div class="product-item-main-info-box">
                <p class="product-item-main-info-title">O153611 号 上海1区，29角色，不可二次，信用分0，无敏感操作，不可发言</p>
                <p class="product-item-main-info-game-zone">游戏区  |  广东区/十六服</p>
                <div class="product-item-main-info-price-box">
                    <p class="product-item-main-info-price-symbol">¥</p>
                    <p class="product-item-main-info-price-text">1600</p>
                </div>
            </div>
        </li>
        <li>
            <div class="product-item-main-pic-box">
                <img class="product-item-main-pic" src="https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/4ee4a03fcbfc47bdab960ba33b2dad4e_mergeImage.png" alt="商品主图" >
            </div>
            <div class="product-item-main-info-box">
                <p class="product-item-main-info-title">O155469 号 双尊剑魔，武器白13，耳环左槽红13，全身11和10，白金玲珑毕</p>
                <p class="product-item-main-info-game-zone">游戏区  |  广东区/十六服</p>
                <div class="product-item-main-info-price-box">
                    <p class="product-item-main-info-price-symbol">¥</p>
                    <p class="product-item-main-info-price-text">1100</p>
                </div>
            </div>
        </li>
        <li>
            <div class="product-item-main-pic-box">
                <img class="product-item-main-pic" src="https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/a41c3333b92a4afeb0d321fb3d41af83_mergeImage.png" alt="商品主图" >
            </div>
            <div class="product-item-main-info-box">
                <p class="product-item-main-info-title"> C645744 号  【安卓QQ166区】【包人脸】 贵族9 荣耀典藏4 传说皮肤13 史诗</p>
                <p class="product-item-main-info-game-zone">游戏区  |  广东区/十六服</p>
                <div class="product-item-main-info-price-box">
                    <p class="product-item-main-info-price-symbol">¥</p>
                    <p class="product-item-main-info-price-text">200</p>
                </div>
            </div>
        </li>
    </ul>
  </div>
</template>

<script>
export default {
    name:"ProdectItem"
}
</script>

<style lang="less" scoped>
.product-item-box{
    width: 1200px;
    ul{
        width: 1200px;
        list-style-type: none;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        li{
            margin-top: 30px;
            margin-left: 20px;
            background: #F8F9FA;
            border-radius: 4px;
            list-style: none;
            width: 275px;
            height: 282px;
            display: flex;
            flex-direction: column;
            .product-item-main-pic-box{
                width: 275px;
                height: 155px;
                .product-item-main-pic{
                    width: 275px;
                    height: 155px;
                }
            }
            .product-item-main-info-box{
                width: 275px;
                padding: 0 14px;
                .product-item-main-info-title{
                    margin-top: 9px;
                    width: 247px;
                    height: 42px;
                    font-family: MicrosoftYaHei;
                    font-size: 14px;
                    color: #131415;
                    line-height: 21px;
                    text-align: left;
                    font-style: normal;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    line-clamp: 2;
                    -webkit-line-clamp: 2;
                    word-break: break-all;
                }
                .product-item-main-info-game-zone{
                    margin-top: 14px;
                    height: 12px;
                    font-family: MicrosoftYaHei;
                    font-size: 12px;
                    color: #888888;
                    line-height: 12px;
                    text-align: left;
                    font-style: normal;
                }
                .product-item-main-info-price-box{
                    margin-top: 20px;
                    height: 16px;
                    display: flex;
                    align-items: flex-end;
                    .product-item-main-info-price-symbol{
                        width: 8px;
                        height: 12px;
                        font-family: DingTalk, DingTalk;
                        font-weight: normal;
                        font-size: 12px;
                        color: #F02233;
                        line-height: 12px;
                        text-align: left;
                        font-style: normal;
                    }
                    .product-item-main-info-price-text{
                        margin-left: 2px;
                        height: 16px;
                        font-family: DingTalk, DingTalk;
                        font-weight: normal;
                        font-size: 16px;
                        color: #F02233;
                        line-height: 16px;
                        text-align: left;
                        font-style: normal;
                    }
                }
            }
        }
    }
}
</style>